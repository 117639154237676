if ('NodeList' in window && !NodeList.prototype.forEach) {
    console.info('polyfill for IE11');
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

(function(){

    // Remove empty <p> tags created by tinymce
    const paragraph = document.querySelectorAll('p') || [];
    paragraph.forEach( e => (e.innerHTML.trim() === '') && e.parentNode.removeChild(e));


    $('.sym-slides').slick({
        accessibility: false
    });

    $('.images-slides').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 500,
        fade: true,
        accessibility: false
    });

    $('.slider-slide').slick({
        arrows: false,
        dots: true,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 700,
        fade: true,
        accessibility: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    adaptiveHeight: true,
                }
            }
        ]
    });

    let ScrollDown = document.getElementsByClassName('js-scrolldown');
    if(ScrollDown.length > 0){
        ScrollDown[0].addEventListener('click', function(){
            animateto('intro');
        });
    }

    //FAQ
    setTimeout(function(){
        $('.tab-label').each(function(){
            let thisHeightTap = $(this).height() + 40;
            let thisHeight = $(this).next('.tab-content').height() + thisHeightTap;
            $(this).parent().animate({height: thisHeightTap + 'px'},400);
            if($(this).hasClass('open')){
                $(this).parent().animate({height: thisHeight + 'px'},400);
            }
        });
        $('.learn-more-btn').append('<div class="arrow"></div>');
    }, 400);

    $('.tab-label').click(function(){
        let currentclick = $(this);
        let thisHeightTap = $(this).height() + 40;

        let slideIndex = $(this).data('artwork');
        $(".accordion-slide").slick('slickGoTo', parseInt(slideIndex));

        if(!currentclick.hasClass('open')){
            $('.tab-label').each(function(){
                if($(this) !== currentclick ){
                    if($(this).hasClass('open')){
                        let thisHeight = $(this).height() + 40;
                        $(this).removeClass('open');
                        $(this).parent().animate({height: thisHeight + 'px'},400);
                    }
                }
            });
            currentclick.addClass('open');
            let thisHeight = $(this).next('.tab-content').height() + thisHeightTap;
            $(this).parent().animate({height: thisHeight + 'px'},400);
        } else {
            currentclick.removeClass('open');
            currentclick.parent().animate({height: thisHeightTap + 'px'},400);
        }
    });

    $('[data-fancybox]').fancybox({
        loop : true,
        backFocus: false,
        autoFocus: false,
    });

    // $("a[href^='#']:not(:where(a[data-fancybox], a[data-filter], a.accordion-toggle, a#js_btn_book))").click(function(e){
    //     e.preventDefault();
    //     let strAncla = $(this).prop('hash').substr(1);
    //     animateto(strAncla);
    // });

    let screenHeight = $(window).height();
    $('#home .top-img figure, #home .top-img video').css("height", screenHeight);

    $(window).resize(function(){
        screenHeight = $(window).height();
        $('#home .top-img figure, #home .top-img video').css("height", screenHeight);
    });

    $('.btn, .signup-btn').each(function() {
        let htmlString = $(this).text();
        let customClickTrackValue = '';

        if (htmlString === "Check Availability") {
            customClickTrackValue = "Navbar | Hotel Res Click | external | event451";
            $(this).html(`<div class="button-inner custom_click_track" data-custom_click_track_value="${customClickTrackValue}"><div class="button-diamond"><div class="button-diamond-inner"><div class="button-diamond-arrow-container"><div class="arrow-line"><div class="arrow-head"></div></div></div></div><div class="button-diamond-hovered"><div class="button-diamond-arrow-container"><div class="arrow-line"><div class="arrow-head"></div></div></div></div></div><div class="button-text"> ${htmlString} </div></div>`);
        } else {
            $(this).html(`<div class="button-inner"><div class="button-diamond"><div class="button-diamond-inner"><div class="button-diamond-arrow-container"><div class="arrow-line"><div class="arrow-head"></div></div></div></div><div class="button-diamond-hovered"><div class="button-diamond-arrow-container"><div class="arrow-line"><div class="arrow-head"></div></div></div></div></div><div class="button-text"> ${htmlString} </div></div>`);
        }
    });

})();

function animateto(target){
    var gotoTarget = offset(document.getElementById(target)).top - 70;
    var from     = window.pageYOffset
    var to       = gotoTarget
    var duration = 1300;
    var start = new Date().getTime()

    //time interval
    var timer = setInterval(function() {
        var time = new Date().getTime() - start

        if(from <= to ){
            var y = easeInOutQuart(time, from, to - from, duration)
        } else {

            var y = easeInOutQuart(time, from, -(from - to), duration)
        }
        window.scrollTo(0, y)
        if (time >= duration) (function(){

            // Remove empty <p> tags created by tinymce
            const paragraph = document.querySelectorAll('p') || [];
            paragraph.forEach( e => (e.innerHTML.trim() === '') && e.parentNode.removeChild(e));


            $('.sym-slides').slick({
                accessibility: false
            });

            $('.images-slides').slick({
                arrows: true,
                dots: false,
                infinite: true,
                autoplay: false,
                autoplaySpeed: 3000,
                speed: 500,
                fade: true,
                accessibility: false
            });

            $('.slider-slide').slick({
                arrows: false,
                dots: true,
                infinite: true,
                autoplay: false,
                autoplaySpeed: 4000,
                speed: 700,
                fade: true,
                accessibility: true,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            adaptiveHeight: true,
                        }
                    }
                ]
            });

            let ScrollDown = document.getElementsByClassName('js-scrolldown');
            if(ScrollDown.length > 0){
                ScrollDown[0].addEventListener('click', function(){
                    animateto('intro');
                });
            }

            //FAQ
            setTimeout(function(){
                $('.tab-label').each(function(){
                    let thisHeightTap = $(this).height() + 40;
                    let thisHeight = $(this).next('.tab-content').height() + thisHeightTap;
                    $(this).parent().animate({height: thisHeightTap + 'px'},400);
                    if($(this).hasClass('open')){
                        $(this).parent().animate({height: thisHeight + 'px'},400);
                    }
                });
                $('.learn-more-btn').append('<div class="arrow"></div>');
            }, 400);

            $('.tab-label').click(function(){
                let currentclick = $(this);
                let thisHeightTap = $(this).height() + 40;

                let slideIndex = $(this).data('artwork');
                $(".accordion-slide").slick('slickGoTo', parseInt(slideIndex));

                if(!currentclick.hasClass('open')){
                    $('.tab-label').each(function(){
                        if($(this) !== currentclick ){
                            if($(this).hasClass('open')){
                                let thisHeight = $(this).height() + 40;
                                $(this).removeClass('open');
                                $(this).parent().animate({height: thisHeight + 'px'},400);
                            }
                        }
                    });
                    currentclick.addClass('open');
                    let thisHeight = $(this).next('.tab-content').height() + thisHeightTap;
                    $(this).parent().animate({height: thisHeight + 'px'},400);
                } else {
                    currentclick.removeClass('open');
                    currentclick.parent().animate({height: thisHeightTap + 'px'},400);
                }
            });

            $('[data-fancybox]').fancybox({
                loop : true,
                backFocus: false,
                autoFocus: false,
            });

            // $("a[href^='#']:not(:where(a[data-fancybox], a[data-filter], a.accordion-toggle, a#js_btn_book))").click(function(e){
            //     e.preventDefault();
            //     let strAncla = $(this).prop('hash').substr(1);
            //     animateto(strAncla);
            // });

            let screenHeight = $(window).height();
            $('#home .top-img figure, #home .top-img video').css("height", screenHeight);

            $(window).resize(function(){
                screenHeight = $(window).height();
                $('#home .top-img figure, #home .top-img video').css("height", screenHeight);
            });

            $('.btn, .signup-btn').each(function() {
                let htmlString = $(this).text();
                $(this).html('<div class="button-inner custom_click_track" data-custom_click_track_value="SearchWidget | Hotel Res Click | external | event451"><div class="button-diamond"><div class="button-diamond-inner"><div class="button-diamond-arrow-container"><div class="arrow-line"><div class="arrow-head"></div></div></div></div><div class="button-diamond-hovered"><div class="button-diamond-arrow-container"><div class="arrow-line"><div class="arrow-head"></div></div></div></div></div><div class="button-text">'+htmlString+'</div></div>');
            });

        })();

        function animateto(target){
            var gotoTarget = offset(document.getElementById(target)).top - 130;
            var from     = window.pageYOffset
            var to       = gotoTarget
            var duration = 1300;
            var start = new Date().getTime()

            //time interval
            var timer = setInterval(function() {
                var time = new Date().getTime() - start

                if(from <= to ){
                    var y = easeInOutQuart(time, from, to - from, duration)
                } else {

                    var y = easeInOutQuart(time, from, -(from - to), duration)
                }
                window.scrollTo(0, y)
                if (time >= duration) clearInterval(timer)

            }, 1000 / 60);

            window.scrollTo(0, from)

        }
//ease movement
        function easeInOutQuart(t, b, c, d) {
            if ((t /= d / 2) < 1) return c / 2 * t * t * t * t + b;
            return -c / 2 * ((t -= 2) * t * t * t - 2) + b;
        }
        function offset(el) {
            var rect = el.getBoundingClientRect(),
                scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
        }clearInterval(timer)

    }, 1000 / 60);

    window.scrollTo(0, from)

}
//ease movement
function easeInOutQuart(t, b, c, d) {
    if ((t /= d / 2) < 1) return c / 2 * t * t * t * t + b;
    return -c / 2 * ((t -= 2) * t * t * t - 2) + b;
}
function offset(el) {
    var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

if (document.querySelector('footer .social-media .facebook')) {
    const facebookButton = document.querySelector('footer .social-media .facebook');
    facebookButton.classList.add('custom_click_track');
    facebookButton.setAttribute(
        'data-custom_click_track_value',
        'Footer |Social Site Click|external|event465'
    );
}
if (document.querySelector('footer .social-media .instagram')) {
    const instagramButton = document.querySelector('footer .social-media .instagram');
    instagramButton.classList.add('custom_click_track');
    instagramButton.setAttribute(
        'data-custom_click_track_value',
        'Footer |Social Site Click|external|event465'
    );
}
if (document.querySelector('.logos-items li')) {
    const bonvoy = document.querySelector('.logos-items li:first-child a');
    bonvoy.classList.add('custom_click_track')
    bonvoy.setAttribute(
        'data-custom_click_track_value',
        'Footer |Marriott Link Click|external|event463'
    );
}

if( document.querySelector("form#form_id-150") ) {
    let form = document.querySelector("form#form_id-150");
    let submitButton = form.querySelector(".btn.custom_click_track");
    submitButton.dataset.custom_click_track_value = "Body: Let's Get Started|Meeting RFP Click|internal|event459";
}

console.log("HOLA MUNDO test tes")

